<template>
  <div
    class="categoryProductPreview"
    :style="`padding-top:${
                landing.advanced.global.active_header_inside ||
                landing.advanced.global.active_logo_inside
                  ? '20px'
                  : `${+landing.branding.topMenu.styles.size * 2.5}px`
              };padding-left:${
      landing.branding.lining_margin ? '10px' : '0'
    };padding-right:${landing.branding.lining_margin ? '10px' : '0'};`"
  >
    <div :style="liningStyles" class="categoryProductPreviewMain" style="min-height:300px;overflow:hidden;">
      <div v-if="content.styles.category.category_style" style="display:flex;flex-direction:column;width:100%;">
        <div   style="display:flex;margin-bottom:30px;flex-direction:column;"
               @click="categoryOpen(category)"
               v-for="category in showCategories"
               :key="category.id">
          <div style="display:flex;align-items:center;margin-bottom:15px;">
            <div
                v-if="content.styles.category.show_icon && category.main_file" :style="`
          height:${content.styles.category.categorySlider.icon.height}px;
          width:${content.styles.category.categorySlider.icon.width}px;
          `" style="margin-right:15px;"
            >
              <img
                  :src="category.main_file"
                  alt=""
                  style="height: 100%; width: 100%"
              />
            </div>
            <div :style="`
            font-size:${content.styles.category.categorySlider.name.font_size};
            color:${content.styles.category.categorySlider.name.color};
            font-weight:${content.styles.category.categorySlider.name.font_weight};
            font-family:${content.styles.category.categorySlider.name.font_family};
          `">

              {{ category.name[landing.current_lang] }}
            </div>
          </div>
          <swiper
              :ref="`mySwiper_${category.id}`"
              :direction="'horizontal'"
              :pagination="{ clickable: true }"
              :options="swiperOptions"

          >
            <swiper-slide :style="`
                          width:${content.styles.category.categorySlider.block.width}px;
                          `"
                          v-for="product in category.products" :key="product.id">
              <div
                  :style="`
                  width:${content.styles.category.categorySlider.block.width}px;
                  `"
                  class="productProductPreviewMainItem"
                  @click.stop="productOpen(product)"
                  style="position:relative;height:100%;"
              >
                <div v-if="product.tags.find(x => x.id === 9)" style="position:absolute;top:0;left:0;background:rgba(255,255,255,0.5);padding:5px;display:flex;align-items: center;">
                  <img :src="`/tags_product/${product.tags.find(x => x.id === 9).img}`" style="width:20px;height:20px;margin-right:5px;">
                  <span style="font-size:13px;">{{product.tags.find(x => x.id === 9).name}}</span>
                </div>

                <div v-if="checkProductTag(product.tags)" style="position:absolute;top:0;right:0;background:rgba(255,255,255,0.5);padding:5px;display:flex;align-items: center;">
                  <img :src="`/tags_product/${checkProductTag(product.tags).img}`" style="width:20px;height:20px;margin-right:5px;">
                  <span style="font-size:13px;">{{checkProductTag(product.tags).name}}</span>
                </div>
                <div
                    class="productProductPreviewMainItemImage"
                    :style="`
      width:${content.styles.product.img_width}%;
        height:${content.styles.product.img_height}px;
      `"
                    v-if="content.styles.product.show_icon"
                >
                  <img
                      :src="
                  product.main_file
                    ? product.main_file
                    : require('@/assets/none.png')
                "
                      alt=""
                      style="width:100%;height:100%;"
                  />
                </div>
                <div class="productProductPreviewMainItemText" style="text-align:center;" :style="`
        color:${content.styles.product.color};
        font-size:${content.styles.product.fontSize}px;
        font-weight:${content.styles.product.fontWeight};
        font-style:${content.styles.product.fontStyle};
        font-family:${content.styles.product.fontFamily};
        justify-content:${content.styles.product.positionName};
      `">
                  {{ product.name[landing.current_lang] }}
                </div>
                <div style="display:flex;width:100%;justify-content:space-between;padding:0 10px;align-items:center;">
                  <div :style="`color:${content.styles.productDetail.color};`" style="display:flex;align-items:center;flex:1;">
                    <span v-if="product.weight" style="display:flex;align-items:center;margin-right:10px;">{{ product.weight }} {{ product.unit }}</span>
                    <span v-if="product.meal_serving_time" style="display:flex;align-items:center;"><v-icon style="margin-right:5px;" size="20px">mdi-clock-outline</v-icon> {{product.meal_serving_time}} m</span>
                  </div>
                  <div class="productProductPreviewMainItemText" :style="`
        color:${content.styles.product.colorPrice};
        font-size:${content.styles.product.fontSizePrice}px;
        font-weight:${content.styles.product.fontWeightPrice};
        font-style:${content.styles.product.fontStylePrice};
        font-family:${content.styles.product.fontFamilyPrice};
        justify-content:${content.styles.product.positionPrice};
      `" style="flex:1;display:flex;text-align:end;">
                    {{ product.price }} {{ product.currency }}
                  </div>
                </div>
                <div
                    style="flex:1;padding:0 10px;"
                    class="productProductPreviewMainItemText"
                    v-html="product.small_description[landing.current_lang]"
                ></div>
                <div
                    style="
                display: flex;
                width: 100%;
                margin: 15px 0;
                justify-content: space-around;
              "
                >
                  <div>
                    <v-btn
                        outlined
                        small
                        dense
                        :style="`background:${content.styles.productDetail.btnBgCart};color:${content.styles.productDetail.btnColorCart};border-color:${content.styles.productDetail.btnBorderCart};`"
                    >
                      {{
                        content.styles.productDetail.btnNameCart[
                            landing.current_lang
                            ]
                      }}
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                        outlined
                        small
                        dense
                        :style="`background:${content.styles.productDetail.btnBgMore};color:${content.styles.productDetail.btnColorMore};border-color:${content.styles.productDetail.btnBorderMore};`"
                    >
                      {{
                        content.styles.productDetail.btnNameMore[
                            landing.current_lang
                            ]
                      }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

      </div>
      <div v-else style="width:100%;display: flex;flex-wrap: wrap;">
        <div
            @click="categoryOpen(category)"
            v-for="category in showCategories"
            :key="category.id"
            :style="styles"
        >
          <div
              class="categoryProductPreviewMainItem"
              :style="`
        min-height:${content.styles.category.height}px;`"
          >
            <div
                class="categoryProductPreviewMainItemImage"
                :style="imgStyles"
                v-if="content.styles.category.show_icon && category.main_file"
            >
              <img :src="category.main_file" alt="" style="width: 100%;height: 100%;"/>
            </div>
            <div v-if="content.styles.category.show_name === 'inside'"
                 class="categoryProductPreviewMainItemText"
                 :style="`justify-content:center;`"
            >
              {{ category.name[landing.current_lang] }}
            </div>
          </div>
          <div v-if="content.styles.category.show_name === 'bottom'"
               class="categoryProductPreviewMainItemText" style="margin-top: 10px;"
               :style="`justify-content:${content.styles.category.positionName}`"
          >
            {{ category.name[landing.current_lang] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
export default {
  name: "CategoryPreview",
  props: ["content"],
  components:{Swiper, SwiperSlide},
  methods: {
    productOpen(item) {
      let arr = this.pageProduct.split("/");
      arr.splice(0, 1);
      let newpage = `productDetail/${arr.join("/")}/${item.id}`;
      this.$store.commit("products/setPageProducts", newpage);
    },
    checkProductTag(tags){
      if(tags.find(x => x.id === 10)){
        return tags.find(x => x.id === 10)
      }else if(tags.find(x => x.id === 11)){
        return tags.find(x => x.id === 11)
      }else if(tags.find(x => x.id === 12)){
        return tags.find(x => x.id === 12)
      }else if(tags.find(x => x.id === 13)){
        return tags.find(x => x.id === 13)
      }else if(tags.find(x => x.id === 14)){
        return tags.find(x => x.id === 14)
      }else{
        return false;
      }
    },
    categoryOpen(item) {
      if (item.products.length) {
        this.$store.commit(
          "products/setPageProducts",
          `productList/${item.id}`
        );
      }
      if (item.sub_categories.length) {
        this.$store.commit(
          "products/setPageProducts",
          `subcategoriesList/${item.id}`
        );
      }
    },
  },
  data(){
    return{
      swiperOptions: {
        grabCursor: true,
        slidesPerView: "auto",
        spaceBetween: 15,
      },
    }
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("products", ["categories"]),
    showCategories() {
      return this.categories.filter((x) => x.active);
    },
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
    imgStyles() {
      return `
      width:${this.content.styles.category.img_width}%;
      height:${this.content.styles.category.img_height}px;
      `;
    },
    styles() {
      return `
        color:${this.content.styles.category.color};
        font-size:${this.content.styles.category.fontSize}px;
        font-weight:${this.content.styles.category.fontWeight};
        font-style:${this.content.styles.category.fontStyle};
        font-family:${this.content.styles.category.fontFamily};
        width:${this.content.styles.category.width}%;
        padding: 10px;
      `;
    },
  },
};
</script>

<style scoped lang="scss">
.categoryProductPreview {
  padding-top: 65px;

  &Main {
    padding: 15px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
      background: #fff;
      box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
      &Image {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      &Text {
        display: flex;
        width: 100%;
        padding: 0 5px;
      }
    }
  }
}
.productProductPreview {
  padding-top: 65px;

  &Main {
    padding: 15px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Item {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 400;
      font-style: normal;
      margin: 0 2% 30px 2%;
      border-radius: 5px;
      cursor: pointer;
      font-family: Inter, Inter, sans-serif;
      color: rgb(0, 0, 0);
      background: #fff;
      box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
      &Image {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      &Text {
        display: flex;
        width: 100%;
        padding: 0 5px;
      }
    }
  }
}
</style>
