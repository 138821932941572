<template>
  <div
    class="productProductPreview"
    :style="`padding-top:${
                landing.advanced.global.active_header_inside ||
                landing.advanced.global.active_logo_inside
                  ? '20px'
                  : `${+landing.branding.topMenu.styles.size * 2.5}px`
              };padding-left:${
      landing.branding.lining_margin ? '10px' : '0'
    };padding-right:${landing.branding.lining_margin ? '10px' : '0'};`"
  >
    <div :style="liningStyles" style="min-height:300px;" class="productProductPreviewMain">
      <div
        @click="productOpen(product)"
        v-for="product in showCategories"
        :key="product.id"
        :style="stylesBlock"
        class="productProductPreviewMainItem"
      >
        <div
          class="productProductPreviewMainItemImage"
          :style="imgStyles"
          v-if="content.styles.product.show_icon"
        >
          <img
            :src="
              product.main_file
                ? product.main_file
                : require('@/assets/none.png')
            "
            alt=""
            style="height: 100%;width: 100%;"
          />
        </div>
        <div class="productProductPreviewMainItemText" :style="stylesName" style="padding:0 10px;">
          {{ product.name[landing.current_lang] }}
        </div>
        <div style="display:flex;width:100%;justify-content:space-between;padding:0 10px;align-items:center;">
          <div :style="`color:${content.styles.productDetail.color};`" style="display:flex;align-items:center;flex:1;">
            <span v-if="product.weight" style="display:flex;align-items:center;margin-right:10px;">{{ product.weight }} {{ product.unit }}</span>
            <span v-if="product.meal_serving_time" style="display:flex;align-items:center;"><v-icon style="margin-right:5px;" size="20px">mdi-clock-outline</v-icon> {{product.meal_serving_time}} m</span>
          </div>
          <div class="productProductPreviewMainItemText" :style="stylesPrice" style="flex:1;">
            {{ product.price }} {{ product.currency }}
          </div>
        </div>

        <div style="padding:0 10px;" v-html="product.small_description[landing.current_lang]"></div>
        <div
          style="
            display: flex;
            width: 100%;
            margin: 15px 0;
            justify-content: space-around;
          "
        >
          <div>
            <v-btn
              @click.stop="addToCart(product)"
              outlined
              small
              dense
              :style="`background:${content.styles.product.btnBgCart};color:${content.styles.product.btnColorCart};border-color:${content.styles.product.btnBorderCart};`"
            >
              {{
                content.styles.product.btnNameCart[landing.current_lang]
              }}
            </v-btn>
          </div>
          <div>
            <v-btn
              outlined
              small
              dense
              :style="`background:${content.styles.product.btnBgMore};color:${content.styles.product.btnColorMore};border-color:${content.styles.product.btnBorderMore};`"
            >
              {{
                content.styles.product.btnNameMore[landing.current_lang]
              }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductListPreview",
  props: ["content"],
  data(){
    return{
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: false,
      },
    }
  },
  methods: {
    addToCart(item) {
      // console.log(item);
    },
    productOpen(item) {
      let arr = this.pageProduct.split("/");
      arr.splice(0, 1);
      let newpage = `productDetail/${arr.join("/")}/${item.id}`;
      this.$store.commit("products/setPageProducts", newpage);
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("products", ["categories", "pageProduct"]),
    showCategories() {
      let arr = this.pageProduct.split("/");
      const candidate = this.categories.find((x) => x.id === +arr[1]);
      if (candidate) {
        if (arr.length === 3) {
          const candidateSubCategory = candidate.sub_categories.find(
            (x) => x.id === +arr[2]
          );
          if (candidateSubCategory) {
            return candidateSubCategory.products.filter(x => x.active);
          } else {
            return [];
          }
        } else {
          return candidate.products.filter(x => x.active);
        }
      } else {
        return [];
      }
    },
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
    imgStyles() {
      return `
      width:${this.content.styles.product.img_width}%;
        height:${this.content.styles.product.img_height}px;
      `;
    },
    stylesBlock() {
      return `
        width:${this.content.styles.product.width}%;
        min-height:${this.content.styles.product.height}px;
      `;
    },
    stylesName() {
      return `
        color:${this.content.styles.product.color};
        font-size:${this.content.styles.product.fontSize}px;
        font-weight:${this.content.styles.product.fontWeight};
        font-style:${this.content.styles.product.fontStyle};
        font-family:${this.content.styles.product.fontFamily};
        justify-content:${this.content.styles.product.positionName};
      `;
    },
    stylesPrice() {
      return `
        color:${this.content.styles.product.colorPrice};
        font-size:${this.content.styles.product.fontSizePrice}px;
        font-weight:${this.content.styles.product.fontWeightPrice};
        font-style:${this.content.styles.product.fontStylePrice};
        font-family:${this.content.styles.product.fontFamilyPrice};
        justify-content:${this.content.styles.product.positionPrice};
      `;
    },
  },
  components:{
  }
};
</script>

<style scoped lang="scss">
.productProductPreview {
  padding-top: 65px;

  &Main {
    padding: 15px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Item {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 400;
      font-style: normal;
      margin: 0 2% 30px 2%;
      border-radius: 5px;
      cursor: pointer;
      font-family: Inter, Inter, sans-serif;
      color: rgb(0, 0, 0);
      background: #fff;
      box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
      &Image {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      &Text {
        display: flex;
        width: 100%;
        padding: 0 5px;
      }
    }
  }
}
</style>
