<template>
  <div>
    <div v-if="pageProduct === 'categoryList'">
      <CategoryPreview :content="content"/>
    </div>
    <div v-if="pageProduct.includes('subcategoriesList')">
      <SubcategoryPreview :content="content"/>
    </div>
    <div v-if="pageProduct.includes('productList')">
      <ProductListPreview :content="content"/>
    </div>
    <div v-if="pageProduct.includes('productDetail')">
      <ProductDetailPreview :content="content"/>
    </div>
  </div>
</template>

<script>
import CategoryPreview from "./typeProduct/CategoryPreview";
import {mapState} from "vuex";
import SubcategoryPreview from "@/components/Constructor/Menu/Preview/typeProduct/SubcategoryPreview";
import ProductListPreview from "@/components/Constructor/Menu/Preview/typeProduct/ProductListPreview";
import ProductDetailPreview from "@/components/Constructor/Menu/Preview/typeProduct/ProductDetailPreview";
export default {
  name: "typeProductPreview",
  components: {ProductDetailPreview, ProductListPreview, SubcategoryPreview, CategoryPreview},
  data(){
    return{

    }
  },
  props:['item','page','content'],
  computed:{
    ...mapState('products',['pageProduct'])
  },
  mounted(){

  }
}
</script>

<style scoped>

</style>