<template>
  <div
    class="categoryProductPreview"
    :style="`padding-top:${
                landing.advanced.global.active_header_inside ||
                landing.advanced.global.active_logo_inside
                  ? '20px'
                  : `${+landing.branding.topMenu.styles.size * 2.5}px`
              };padding-left:${
      landing.branding.lining_margin ? '10px' : '0'
    };padding-right:${landing.branding.lining_margin ? '10px' : '0'};`"
  >
    <div :style="liningStyles" style="min-height:300px;" class="categoryProductPreviewMain">
      <div
        @click="categoryOpen(category)"
        v-for="category in showCategories"
        :key="category.id"
        :style="styles"
      >
        <div
                class="categoryProductPreviewMainItem" :style="`
        min-height:${content.styles.subcategory.height}px;`">
          <div
            class="categoryProductPreviewMainItemImage"
            :style="imgStyles"
            v-if="content.styles.subcategory.show_icon && category.main_file"
          >
            <img :src="category.main_file" alt="" style="height: 100%;width: 100%;" />
          </div>
          <div
                  v-if="content.styles.subcategory.show_name === 'inside'"
            class="categoryProductPreviewMainItemText"
            :style="`justify-content:${content.styles.subcategory.positionName}`"
          >
            {{ category.name[landing.current_lang] }}
          </div>
        </div>
          <div
                  v-if="content.styles.subcategory.show_name === 'bottom'"
                  style="margin-top: 10px;"
                  class="categoryProductPreviewMainItemText"
                  :style="`justify-content:${content.styles.subcategory.positionName}`"
          >
              {{ category.name[landing.current_lang] }}
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SubcategoryPreview",
  props: ["content"],
  methods: {
    categoryOpen(item) {
      if (item.products.length) {
        this.$store.commit(
          "products/setPageProducts",
          `productList/${this.pageProduct.split("/")[1]}/${item.id}`
        );
      }
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("products", ["categories", "pageProduct"]),
    showCategories() {
      const candidate = this.categories.find(
        (x) => x.id === +this.pageProduct.split("/")[1]
      );
      if (candidate) {
        return candidate.sub_categories.filter((x) => x.active);
      } else {
        return [];
      }
    },
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
    imgStyles() {
      return `
      width:${this.content.styles.subcategory.img_width}%;
      height:${this.content.styles.subcategory.img_height}px;
      `;
    },
    styles() {
      return `
        color:${this.content.styles.subcategory.color};
        font-size:${this.content.styles.subcategory.fontSize}px;
        font-weight:${this.content.styles.subcategory.fontWeight};
        font-style:${this.content.styles.subcategory.fontStyle};
        font-family:${this.content.styles.subcategory.fontFamily};
        width:${this.content.styles.subcategory.width}%;
        height:100%;
        padding:10px;
      `;
    },
  },
};
</script>

<style scoped lang="scss">
.categoryProductPreview {
  padding-top: 65px;

  &Main {
    padding: 15px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      height:100%;
      width:100%;
      cursor: pointer;
      background: #fff;
      box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
      &Image {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
      &Text {
        display: flex;
        width: 100%;
        padding: 0 5px;
      }
    }
  }
}
</style>
