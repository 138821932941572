<template>
  <div
    class="productProductPreview"
    v-if="product"
    :style="`padding-left:${
      landing.branding.lining_margin ? '10px' : '0'
    };padding-right:${landing.branding.lining_margin ? '10px' : '0'};`"
  >
    <div :style="liningStyles+`min-height:300px;margin-top:${product.detail_files.length?'0':`${+landing.branding.topMenu.styles.size * 2.5}px`};`" class="productProductPreviewMain">
      <div :style="stylesBlock" class="productProductPreviewMainItem">
        <div
          class="productProductPreviewMainItemImage"
          :style="imgStyles"
          v-if="content.styles.productDetail.show_icon&&product.detail_files.length"
        >
            <swiper
                    ref="mySwiperDetail"
                    :direction="'horizontal'"
                    :pagination="{ clickable: true }"
                    :options="swiperOptionsListing"
                    style="width: 100%; overflow: hidden;position: relative"
                    v-if="product.detail_files.length"
            >
                <swiper-slide v-for="img in product.detail_files.filter(x => x)" :key="img">
          <img
            :src="
              img
            "
            alt=""
            :style="imgStyles"
          />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" />
            </swiper>
        </div>

          <div class="productProductPreviewMainItemText" style="text-align:center;padding-top:15px;">
            <div :style="stylesName">
              {{ product.name[landing.current_lang] }}
            </div>
          </div>
        <div style="display:flex;width:100%;justify-content:space-between;padding:0 10px;align-items:center;">
          <div :style="`color:${content.styles.productDetail.color};`" style="display:flex;align-items:center;flex:1;">
            <span v-if="product.weight" style="display:flex;align-items:center;margin-right:10px;">{{ product.weight }} {{ product.unit }}</span>
            <span v-if="product.meal_serving_time" style="display:flex;align-items:center;"><v-icon style="margin-right:5px;" size="20px">mdi-clock-outline</v-icon> {{product.meal_serving_time}} m</span>
          </div>
          <div class="productProductPreviewMainItemText" :style="stylesPrice" style="flex:1;">
            {{ product.price }} {{ product.currency }}
          </div>
        </div>
        <div style="padding:0 10px;"
          v-html="checkAller(product.description[landing.current_lang])"
        ></div>
        <div
          v-for="option in product.options"
          :key="option.id"
          style="
            display: flex;
            width: 100%;
            margin-bottom: 15px;
            justify-content: flex-start;
          "
        >
          <div style="flex: 1">{{ option.name[landing.current_lang] }}</div>
          <vue-numeric-input
            style="margin-right: 15px; width: 100px; flex: 1"
            width="100px"
            v-model="option.value"
            inline
            controls
          />
          <div style="justify-self: flex-end; flex: 1">
            {{ option.price }} {{ product.currency }}
          </div>
        </div>
        <div style="margin: 15px 0;display:flex;align-items:center;justify-content:space-around;width:100%;">
          <v-btn
            outlined
            small
            dense
            :style="`background:${content.styles.productDetail.btnBgCart};color:${content.styles.productDetail.btnColorCart};border-color:${content.styles.productDetail.btnBorderCart};`"
          >
            {{ content.styles.productDetail.btnNameCart[landing.current_lang] }}
          </v-btn>
          <div>
            or
          </div>
          <v-btn
              outlined
              small
              dense
              :style="`background:${content.styles.productDetail.btnBgOpenCart};color:${content.styles.productDetail.btnColorOpenCart};border-color:${content.styles.productDetail.btnBorderOpenCart};`"
          >
            {{
              content.styles.productDetail.btnNameOpenCart[
                  landing.current_lang
                  ]
            }}
          </v-btn>
        </div>

        <div
          v-if="product.recommended.length"
          style="margin: 10px; width: 100%; overflow: hidden"
        >
          <label class="label" style="margin-bottom: 15px" :style="stylesTitle_slider">Recommended</label>
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              v-for="(slide, i) in product.recommended"
              :key="i"
              :style="`width:${content.styles.productDetail.slider.width}px`"
            >
              <div
                style="display: flex; flex-direction: column"
                :style="`background:${content.styles.productDetail.slider.background};width:${content.styles.productDetail.slider.width}px;border:1px solid ${content.styles.productDetail.slider.border_color};min-height:${content.styles.productDetail.slider.height}px;`"
              >
                <div style="width: 100%">
                  <img
                    :src="
                      slide.main_file
                        ? slide.main_file
                        : require('@/assets/none.png')
                    "
                    :alt="slide.name[landing.current_lang]"
                    style="width: 100%; object-fit: cover"
                    :style="`height:${content.styles.productDetail.slider.img_height}px;`"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    flex:1;
                    padding:0 10px;
                  "
                >
                  <div
                    class="productProductPreviewMainItemText"
                    :style="stylesName_slider"
                  >
                    <div
                      :style="`font-weight:${content.styles.productDetail.fontWeight};`"
                    >
                      {{ slide.name[landing.current_lang] }}
                    </div>
                    <div v-if="+slide.weight">{{ slide.weight }} {{ slide.unit }}</div>
                  </div>
                  <div
                    class="productProductPreviewMainItemText"
                    :style="stylesPrice_slider"
                  >
                    {{ slide.price }} {{ landing.advanced.global.currency }}
                  </div>
                </div>
                <div
                  style="
                    padding: 10px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-btn
                    outlined
                    small
                    dense
                    :style="`background:${content.styles.productDetail.btnBgCart};color:${content.styles.productDetail.btnColorCart};border-color:${content.styles.productDetail.btnBorderCart};`"
                  >
                    {{
                      content.styles.productDetail.btnNameCart[
                        landing.current_lang
                      ]
                    }}
                  </v-btn>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div
          v-if="product.similar.length"
          style="margin: 10px; width: 100%; overflow: hidden"
        >
          <label class="label" style="margin-bottom: 15px" :style="stylesTitle_slider">Similar</label>
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              v-for="(slide, i) in product.similar"
              :key="i"
              :style="`width:${content.styles.productDetail.slider.width}px`"
            >
              <div
                style="display: flex; flex-direction: column"
                :style="`background:${content.styles.productDetail.slider.background};width:${content.styles.productDetail.slider.width}px;border:1px solid ${content.styles.productDetail.slider.border_color};min-height:${content.styles.productDetail.slider.height}px;`"
              >
                <div style="width: 100%">
                  <img
                    :src="
                      slide.main_file
                        ? slide.main_file
                        : require('@/assets/none.png')
                    "
                    :alt="slide.name[landing.current_lang]"
                    style="width: 100%; object-fit: cover"
                    :style="`height:${content.styles.productDetail.slider.img_height}px;`"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    flex:1;
                    padding:0 10px;
                  "
                >
                  <div
                    class="productProductPreviewMainItemText"
                    :style="stylesName_slider"
                  >
                    <div
                      :style="`font-weight:${content.styles.productDetail.fontWeight};`"
                    >
                      {{ slide.name[landing.current_lang] }}
                    </div>
                    <div v-if="+slide.weight">{{ slide.weight }} {{ slide.unit }}</div>
                  </div>
                  <div
                    class="productProductPreviewMainItemText"
                    :style="stylesPrice_slider"
                  >
                    {{ slide.price }} {{ landing.advanced.global.currency }}
                  </div>
                </div>
                <div
                  style="
                    padding: 10px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-btn
                    outlined
                    small
                    dense
                    :style="`background:${content.styles.productDetail.btnBgCart};color:${content.styles.productDetail.btnColorCart};border-color:${content.styles.productDetail.btnBorderCart};`"
                  >
                    {{
                      content.styles.productDetail.btnNameCart[
                        landing.current_lang
                      ]
                    }}
                  </v-btn>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
export default {
  name: "ProductDetailPreview",
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 15,
      },
        swiperOptionsListing: {
            grabCursor: true,
            centeredSlides: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
            },
        },
    };
  },
  props: ["content"],
  methods: {
    checkAller(text) {
      if (text) {
        let newText = text.split("").join("");
        newText = newText.replaceAll(":allergen:", this.product.allergens);
        let tags = "";
        for (let i = 0; i < this.product.tags.length; i++) {
          tags += `<p><img src="/tags_product/${this.product.tags[i].img}" alt="${this.product.tags[i].name}" style="width:20px;height:20px;margin:0 5px;"/><span> ${this.product.tags[i].name} </span></p>`;
        }
        newText = newText.replace(":tags:", tags);
        return newText;
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("products", ["categories", "pageProduct"]),
    recommended() {
      return this.categories.reduce((acc, item) => {
        if (item.products.length) {
          item.products.forEach((product) => {
            if (this.product.recommended.includes(product.id)) {
              acc.push(product);
            }
          });
        }
        if (item.sub_categories.length) {
          item.sub_categories.forEach((subCategory) => {
            if (subCategory.products.length) {
              subCategory.products.forEach((product) => {
                if (this.product.recommended.includes(product.id)) {
                  acc.push(product);
                }
              });
            }
          });
        }
        return acc;
      }, []);
    },
    similar() {
      return this.categories.reduce((acc, item) => {
        if (item.products.length) {
          item.products.forEach((product) => {
            if (this.product.similar.includes(product.id)) {
              acc.push(product);
            }
          });
        }
        if (item.sub_categories.length) {
          item.sub_categories.forEach((subCategory) => {
            if (subCategory.products.length) {
              subCategory.products.forEach((product) => {
                if (this.product.similar.includes(product.id)) {
                  acc.push(product);
                }
              });
            }
          });
        }
        return acc;
      }, []);
    },
    product() {
      let arr = this.pageProduct.split("/");
      const candidate = this.categories.find((x) => x.id === +arr[1]);
      if (candidate) {
        if (arr.length === 4) {
          const candidateSubCategory = candidate.sub_categories.find(
            (x) => x.id === +arr[2]
          );
          if (candidateSubCategory) {
            const candidateProduct = candidateSubCategory.products.find(
              (x) => x.id === +arr[3]
            );
            if (candidateProduct) {
              return candidateProduct;
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          const candidateProduct = candidate.products.find(
            (x) => x.id === +arr[2]
          );
          if (candidateProduct) {
            return candidateProduct;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    },
    liningStyles() {
        let styles = "";
      if (this.landing.branding.bg_lining_img) {
        styles+= `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        styles+=`background-color:${this.landing.branding.bg_lining_color};`;
      }
      if(this.product.detail_files.length){
          styles+=`border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
      }else{
          styles+=`border-radius:${this.landing.branding.lining_radius}px;`;
      }
      return styles
    },
    imgStyles() {
      return `
      width:100%;
      height:${this.content.styles.productDetail.img_height}px;
      `;
    },
    stylesBlock() {
      return `
        width:100%;
        height:auto;
        background:transparent;
      `;
    },
    stylesName() {
      return `
        color:${this.content.styles.productDetail.color};
        font-size:${this.content.styles.productDetail.fontSize}px;
        font-style:${this.content.styles.productDetail.fontStyle};
        font-family:${this.content.styles.productDetail.fontFamily};
        font-weight:${this.content.styles.productDetail.fontWeight};
        align-items:flex-start;
      `;
    },
    stylesTitle_slider(){
      return `
        color:${this.content.styles.productDetail.slider.title.color};
        font-size:${this.content.styles.productDetail.slider.title.font_size}px;
        font-weight:${this.content.styles.productDetail.slider.title.font_weight};
        font-style:${this.content.styles.productDetail.slider.title.font_style};
        font-family:${this.content.styles.productDetail.slider.title.font_family};
      `;
    },
    stylesName_slider(){
      return `
        color:${this.content.styles.productDetail.slider.name.color};
        font-size:${this.content.styles.productDetail.slider.name.font_size}px;
        font-weight:${this.content.styles.productDetail.slider.name.font_weight};
        font-style:${this.content.styles.productDetail.slider.name.font_style};
        font-family:${this.content.styles.productDetail.slider.name.font_family};
      `;
    },
    stylesPrice_slider(){
      return `
        color:${this.content.styles.productDetail.slider.price.color};
        font-size:${this.content.styles.productDetail.slider.price.font_size}px;
        font-weight:${this.content.styles.productDetail.slider.price.font_weight};
        font-style:${this.content.styles.productDetail.slider.price.font_style};
        font-family:${this.content.styles.productDetail.slider.price.font_family};
        align-items:flex-end;
      `;
    },
    stylesPrice() {
      return `
        color:${this.content.styles.productDetail.colorPrice};
        font-size:${this.content.styles.productDetail.fontSizePrice}px;
        font-weight:${this.content.styles.productDetail.fontWeightPrice};
        font-style:${this.content.styles.productDetail.fontStylePrice};
        font-family:${this.content.styles.productDetail.fontFamilyPrice};
        align-items:flex-end;
      `;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped lang="scss">
.productProductPreview {
  &Main {
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Item {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 400;
      font-style: normal;
      border-radius: 5px;
      cursor: pointer;
      font-family: Inter, Inter, sans-serif;
      color: rgb(0, 0, 0);

      &Image {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
      }
      &Text {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 5px;
      }
    }
  }
}
</style>
